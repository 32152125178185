export type RegistryEntry = {
  image: string;
  text: string;
  price: number;
  totalStock: number;
  currentStock: number;
};

export const registryMap: RegistryEntry[] = [
  {
    image: 'https://gabrieltnishimura.github.io/unite/registry/car-rental.webp',
    text: 'Diaria do aluguel de carro',
    price: 150 * 3,
    totalStock: 15,
    currentStock: 8,
  },
  {
    image: 'https://gabrieltnishimura.github.io/unite/registry/restaurant.webp',
    text: 'Refeição em Nova Zelândia',
    price: 210,
    totalStock: 30,
    currentStock: 13,
  },
  {
    image: 'https://gabrieltnishimura.github.io/unite/registry/ski-rental.webp',
    text: 'Aluguel de equipamento de ski',
    price: 200 * 3,
    totalStock: 2,
    currentStock: 0,
  },
  {
    image: 'https://gabrieltnishimura.github.io/unite/registry/skydiving.webp',
    text: 'Pular de para-quedas em Queenstown',
    price: 415 * 3,
    totalStock: 2,
    currentStock: 0,
  },
  {
    image: 'https://gabrieltnishimura.github.io/unite/registry/zip.webp',
    text: 'Tiroleza em Christchurch',
    price: 110 * 3,
    totalStock: 2,
    currentStock: 0,
  },
  {
    image: 'https://gabrieltnishimura.github.io/unite/registry/hotel-cardrona.webp',
    text: 'Diaria no hotel em Cardrona',
    price: 659,
    totalStock: 3,
    currentStock: 0,
  },
  {
    image: 'https://gabrieltnishimura.github.io/unite/registry/hotel-cook.webp',
    text: 'Diaria no hotel em Monte Cook',
    price: 824,
    totalStock: 3,
    currentStock: 0,
  },
  {
    image: 'https://gabrieltnishimura.github.io/unite/registry/hotel-hamilton.webp',
    text: 'Diaria no hotel em Hamilton',
    price: 616,
    totalStock: 1,
    currentStock: 0,
  },
  {
    image: 'https://gabrieltnishimura.github.io/unite/registry/hotel-christchurch.webp',
    text: 'Diaria no hotel em Christchurch',
    price: 514,
    totalStock: 2,
    currentStock: 0,
  },
  {
    image: 'https://gabrieltnishimura.github.io/unite/registry/hotel-rotorua.webp',
    text: 'Diaria no hotel em Rotorua',
    price: 616,
    totalStock: 2,
    currentStock: 0,
  },
  {
    image: 'https://gabrieltnishimura.github.io/unite/registry/hotel-auckland.webp',
    text: 'Diaria no hotel em Auckland',
    price: 698,
    totalStock: 2,
    currentStock: 0,
  },
  {
    image: 'https://gabrieltnishimura.github.io/unite/registry/rotorua.webp',
    text: 'Passagem de Christchurch para Rotorua',
    price: 255,
    totalStock: 2,
    currentStock: 0,
  },
  {
    image: 'https://gabrieltnishimura.github.io/unite/registry/onsen.webp',
    text: 'Relaxamento no Onsen Hot Pools',
    price: 1140,
    totalStock: 2,
    currentStock: 0,
  },
  {
    image: 'https://gabrieltnishimura.github.io/unite/registry/ski.webp',
    text: 'Lift do resort de ski',
    price: 200 * 3,
    totalStock: 2,
    currentStock: 0,
  },
  {
    image: 'https://gabrieltnishimura.github.io/unite/registry/maori.webp',
    text: 'Experiência cultural dos Maoris',
    price: 155 * 3,
    totalStock: 2,
    currentStock: 0,
  },
  {
    image: 'https://gabrieltnishimura.github.io/unite/registry/shotover.webp',
    text: 'Passeio radical de barco',
    price: 159 * 3,
    totalStock: 2,
    currentStock: 0,
  },
  {
    image: 'https://gabrieltnishimura.github.io/unite/registry/milford.webp',
    text: 'Cruzeiro em Milford Sound',
    price: 975,
    totalStock: 2,
    currentStock: 0,
  },
  {
    image: 'https://gabrieltnishimura.github.io/unite/registry/glacier.webp',
    text: 'Trilha nas geleiras Tasman',
    price: 799 * 3,
    totalStock: 2,
    currentStock: 0,
  },
  {
    image: 'https://gabrieltnishimura.github.io/unite/registry/gondola.webp',
    text: 'Gondola em Christchurch',
    price: 77 * 3,
    totalStock: 2,
    currentStock: 0,
  },
  {
    image: 'https://gabrieltnishimura.github.io/unite/registry/kiwi.webp',
    text: 'Parque Kiwi Birdlife',
    price: 171.62,
    totalStock: 2,
    currentStock: 0,
  },
  {
    image: 'https://gabrieltnishimura.github.io/unite/registry/hobbiton.webp',
    text: 'Tour do cenario de filme Hobbiton',
    price: 365.04,
    totalStock: 2,
    currentStock: 0,
  },
  {
    image: 'https://gabrieltnishimura.github.io/unite/registry/lotr.webp',
    text: 'Aventura de Safari - Senhor dos Anéis',
    price: 270 * 3,
    totalStock: 2,
    currentStock: 0,
  },
  {
    image: 'https://gabrieltnishimura.github.io/unite/registry/hotel-queenstown.webp',
    text: 'Diária no hotel em Queenstown',
    price: 725,
    totalStock: 3,
    currentStock: 0,
  },
  {
    image: 'https://gabrieltnishimura.github.io/unite/registry/glowworm.webp',
    text: 'Experiência na caverna Waitomo Glowworm',
    price: 1014,
    totalStock: 2,
    currentStock: 0,
  },
];
